.products-preview {
  display: flex;
  flex-direction: column;
  margin-bottom: 24px;

  &__header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 16px;
    padding-bottom: 16px;
    border-bottom: 1px solid var(--global-black-10);
  }

  &__title {
    margin: 0;
    font-size: 22px;
    font-weight: 400;
    color: var(----global-black);
  }

  &__price {
    margin: 0;
    font-size: 16px;
    color: var(----global-black);
  }
}
