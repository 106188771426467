.product-page {
	&__rating {
		display: flex;
		flex-direction: row;
		justify-content: flex-end;
		align-items: center;
		margin-right: 8px;
		margin-bottom: 24px;
	}

	&__code {
		margin: 0px;
		font-size: 14px;

		span {
			color: var(--global-grey-50);
		}
	}
}

.carousel-skeleton {
	width: 100%;
	height: 429px;
	margin-bottom: 32px;
	border-radius: 4px;
	background-color: var(--global-20);
}

.product-variants {
	margin-bottom: 32px;
}

.product-trade {
	position: relative;
	display: flex;
	flex-direction: column;
	flex-wrap: wrap;
	margin-bottom: 48px;
	padding: 0px 16px 16px 16px;
	border-radius: 4px;
	border: 1px solid var(--global-black-10);

	&__inner {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: center;
		margin-bottom: 8px;
		padding-top: 16px;
	}

	&__buttons {
		display: flex;
	}

	&__price {
		margin: 0;
		font-size: 24px;
		color: var(--global);

		&--red {
			color: var(--global-red);
		}
	}

	&__discount {
		position: absolute;
		left: 20px;
		top: 0;
		display: inline-block;
		margin: 0;
		padding: 0 12px;
		transform: translateY(-50%);
		font-size: 18px;
		color: var(--global-grey-30);

		&::before {
			content: '';
			position: absolute;
			left: 50%;
			top: 50%;
			transform: translate(-50%, -50%);
			display: block;
			height: 2px;
			width: calc(105% - 24px);
			background-color: var(--global-red);
		}
	}

	&__buy-button {
		cursor: pointer;
		display: flex;
		flex-direction: row;
		justify-content: center;
		align-items: center;
		width: 100%;
		height: 40px;
		border-radius: 4px;
		border: none;
		background-color: var(--global);
		color: var(--global-white);
		font-size: 16px;
		transition: background-color 0.2s ease-in-out;

		&:hover {
			background-color: var(--global-50);
		}

		&:disabled {
			background-color: var(--global-50);
		}

		svg {
			margin-right: 12px;
		}
	}

	&__redirect-to-cart {
		cursor: pointer;
		height: 40px;
		width: 100%;
		align-items: center;
		display: flex;
		flex-direction: row;
		justify-content: center;
		color: var(--link-color);

		&:hover {
			color: var(--link-hover-color);
		}

		span {
			border-bottom: 1px dotted;
			font-size: 16px;
			font-weight: 400;
		}

		svg {
			fill: var(--global-50);
			margin-right: 12px;
		}
	}
}

.product-about {
	display: flex;
	flex-direction: column;

	&__heading {
		margin-top: 0;
		margin-bottom: 24px;
		word-break: break-word;
		font-size: 22px;
		font-weight: 400;

		span {
			color: var(--global-grey-30);
		}
	}

	&__collapse-button {
		cursor: pointer;
		line-height: 16px;
		font-size: 14px;
		color: var(--link-color);

		&:hover {
			color: var(--link-hover-color);
		}
	}

	&__description {
		position: relative;
		margin-bottom: 48px;

		&-content {
			word-break: break-word;
			font-size: 14px;

			&.state_collapsed {
				position: relative;
				max-height: 200px;
				overflow: hidden;

				&::after {
					position: absolute;
					bottom: 0;
					left: 0;
					display: block;
					width: 100%;
					height: 100px;
					background: linear-gradient(180deg, hsla(0, 0%, 100%, 0), #fff);
					content: '';
				}
			}

			img {
				max-width: 100%;
				height: auto;
			}
		}
	}
}
