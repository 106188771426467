.products-page {
	&__header {
		display: flex;
		align-items: center;
		justify-content: space-between;
	}

	&__reset-category-button {
		cursor: pointer;
		display: flex;
		flex-direction: row;
		justify-content: center;
		align-items: center;
		width: 56px;
		height: 56px;
		border: none;
		background-color: var(--global-white);
		transition: all 0.2s ease;

		&:hover {
			svg {
				fill: var(--link-hover-color);
			}
		}

		svg {
			width: 24px;
			height: 24px;
			fill: var(--global-black-60);
			transition: all 0.2s ease;
		}
	}

	&__actions-container {
		position: sticky;
		top: 56px;
		z-index: 3;
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: center;
		width: 100%;
		padding-top: 8px;
		padding-bottom: 8px;
		border-bottom: 1px solid var(--global-black-10);
		background-color: var(--global-white);
	}

	&__actions {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		width: 100%;
		padding-right: 20px;
	}

	&__filter-button {
		cursor: pointer;
		display: flex;
		flex-direction: row;
		justify-content: center;
		align-items: center;
		min-width: 108px;
		height: 40px;
		border-radius: 4px;
		border: none;
		background: var(--global-black);
		font-size: 12px;
		color: var(--global-white);

		&:hover {
			svg {
				fill: var(--global-60);
			}
		}

		svg {
			margin-right: 5px;
			font-size: 24px;
			color: var(--global-white);
		}
	}

	&__order-select {
		height: 40px;
		font-size: 12px !important;

		&:focus {
			outline: var(--global-red) !important;
		}
	}

	&__switch {
		display: flex;
		flex-direction: row;
		justify-content: flex-end;

		svg {
			font-size: 24px;
		}
	}

	&__switch-button {
		width: 40px !important;
		height: 40px !important;
		margin: 1px !important;
		border-radius: 4px !important;

		&:disabled {
			background-color: var(--global-20) !important;
			color: var(--global-black) !important;
		}
	}
}
