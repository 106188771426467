.auth-redirect {
	display: flex;
	align-items: center;
	justify-content: center;
	height: 100vh;

	.auth-redirect__content {
		background-color: var(--global-white);
		border-radius: 12px;
		box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
		padding: 30px;
		text-align: center;
		max-width: 400px;
		width: 90%;
		transition: transform 0.3s ease-in-out;

		&:hover {
			transform: translateY(-5px);
		}

		h2 {
			font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
			font-weight: 500;
		}
	}
}
