.product-tile {
	display: flex;
	width: 100%;
	height: auto;
	padding: 16px;
	border-bottom: 1px solid var(--global-black-10);
	border-right: 1px solid var(--global-black-10);
	background-color: var(--global-white);

	&.tile {
		cursor: pointer;
		flex-direction: column;
		align-items: flex-start;
		height: 320px;

		.product-tile__picture {
			width: 100%;
			height: 50%;
		}
	}

	&.row {
		flex-direction: row;
		box-sizing: content-box;
		height: 128px;

		.product-tile__details {
			width: 70%;
			padding-top: 0;
			padding-bottom: 0;
			padding-left: 10px;
		}

		.product-tile__picture {
			height: 100%;
			width: 30%;
		}

		.product-tile__title {
			margin: 0;
		}

		.product-tile__price {
			margin-top: 20px;
		}

		.product-tile__price-current {
			padding-top: 0;
		}
	}

	&__details {
		width: 100%;
		height: 50%;
		padding-top: 10px;
	}

	&__picture {
		img {
			max-width: 100%;
			max-height: 100%;
			width: auto;
			height: auto;
			border-radius: 4px;
		}
	}

	&__skeleton {
		width: 100%;
		height: 100%;
		border-radius: 4px;
		background-color: var(--global-20);
	}

	&__title {
		height: 32px;
		margin-bottom: 8px;
		overflow: hidden;
		font-size: 12px;
		line-height: 16px;
		color: var(--global-black);
		font-weight: 500;
	}

	&__price-value {
		display: flex;
		flex-direction: column;
		justify-content: flex-end;
		height: 50px;
		padding-bottom: 4px;
		width: 85%;
	}

	&__price-current {
		font-size: 20px;
		padding-top: 5px;
		overflow: hidden;
		white-space: nowrap;
		text-overflow: ellipsis;
	}

	&__price-old {
		height: 18px;
		font-size: 14px;
		overflow: hidden;
		white-space: nowrap;
		text-overflow: ellipsis;
	}

	&__quantity {
		margin-top: 10px;
		font-size: 12px;
		color: var(--global);

		p {
			margin: 0;
		}
	}

	&__controls {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: flex-end;
		margin-top: 10px;
	}

	.price-grey {
		color: var(--global-black-60);
		text-decoration: line-through;
	}

	.price-red {
		color: var(--global-red);
	}
}
