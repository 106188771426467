.address-picker {
  display: flex;
  flex-direction: column;
  padding-top: 16px;

  .MuiAutocomplete-root {
    margin-top: 4px;
    margin-bottom: 8px;
  }

  &__street {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  &__label {
    margin-top: 4px;
    margin-bottom: 0;
    font-size: 12px;
    color: var(--global-black-60);
  }
}
