.search-suggest {
	position: absolute;
	left: 0;
	top: 100%;
	z-index: 90;
	width: 100%;
	padding: 8px 0;
	border-radius: 0 0 3px 3px;
	border-top: 1px solid var(--global-black-10);
	overflow: hidden;
	background-color: var(--global-white);

	&__show-all {
		cursor: pointer;
		display: flex;
		padding: 0 16px;
		font-size: 14px;
		line-height: 32px;
		color: var(--link-color);

		&:hover {
			color: var(--link-hover-color);
			text-decoration: underline;
		}
	}

	&__heading {
		display: flex;
		flex-direction: row;
		align-items: center;
		padding: 0 16px;
		line-height: 32px;
		font-size: 11px;
		color: var(--global-black-60);
	}

	&__group {
		border-bottom: 1px solid var(--global-black-10);
	}

	&__item {
		&-content {
			cursor: pointer;
			display: flex;
			align-items: center;
			padding: 0 16px;
			line-height: 32px;
			font-size: 14px;
			text-decoration: none;
			color: var(--global-black);

			&:hover {
				background-color: var(--global-black-05);
			}

			svg {
				margin-right: 16px;
				fill: var(--global-grey-30);
			}
		}

		&-caption {
			overflow: hidden;
			white-space: nowrap;
			text-overflow: ellipsis;
			color: var(--global-grey-95);
		}

		&--no-result {
			margin: 0;
			padding: 0 16px;
			line-height: 32px;
			font-size: 14px;
			color: var(--global-black);
		}
	}
}

.suggest-goods {
	display: flex;
	flex-direction: row;
	padding: 16px;
	border-bottom: 1px solid var(--global-black-10);

	&:hover {
		background-color: var(--global-black-05);
	}

	&__image {
		width: 48px;
		max-height: 70px;
		margin-right: 16px;

		img {
			max-width: 100%;
			max-height: 100%;
			width: auto;
			height: auto;
			border-radius: 4px;
		}
	}

	&__skeleton {
		width: 100%;
		height: 100%;
		border-radius: 4px;
		background-color: var(--global-20);
	}

	&__title {
		display: block;
		font-size: 14px;
		color: var(--global-black);
		white-space: normal;
	}

	&__price {
		display: inline-block;
		margin-right: 8px;
		font-size: 17px;
		color: var(--global-black);
	}
}
