html {
	--global-black: #221f1f;
	--global-black-10: #e9e9e9;
	--global-black-05: #f5f5f5;
	--global-black-60: #797878;
	--global-white: #fff;
	--global-grey: #888888;
	--global-grey-95: #999999;
	--global-grey-50: #a6a5a5;
	--global-grey-30: #d2d2d2;
	--global-red: #f84147;
	--link-color: #3e77aa;
	--link-hover-color: #ff7878;
	--header-hover-color: #fff3;
	--button-shadow: #ebebeb;
	--box-shadow: #221f1f4d;
	--global-yellow: #ffa900;
	--global-yellow-05: #fff6d2;
}
