.destination-input {
  display: flex;
  flex-direction: column;
  padding-top: 16px;

  &__label {
    margin: 4px 0;
    font-size: 12px;
    color: var(--global-black-60);
  }
}
