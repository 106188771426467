.card-tile {
	display: flex;
	flex-direction: column;
	width: 100%;
	padding: 16px;
	border-top: 1px solid var(--global-black-10);
	font-size: 14px;

	&__skeleton {
		min-width: 76px;
		min-height: 76px;
		width: 76px;
		border-radius: 4px;
		background-color: var(--global-20);
	}

	&__old-price {
		font-size: 14px !important;
		color: var(--global-grey-50) !important;
		text-decoration: line-through;
	}

	&__new-price {
		color: var(--global-red) !important;
	}

	&__item {
		display: flex;
		flex-direction: row;
		justify-content: space-between;

		span {
			display: flex;
			justify-content: center;
			align-items: center;
			width: 76px;
			height: 76px;
		}

		img {
			max-width: 100%;
			max-height: 100%;
			width: auto;
			height: auto;
			border-radius: 4px;
		}

		p {
			flex-grow: 1;
			margin: 0;
			padding: 0 16px;
		}
	}

	&__footer {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: center;
		padding: 16px 0;

		p {
			margin: 0;
			font-size: 20px;
			color: var(--global-black);
		}
	}

	&__quantity-selector {
		display: flex;
		flex-direction: row;
		align-items: center;

		input {
			display: flex;
			align-items: center;
			text-align: center;
			justify-content: center;
			height: 40px;
			width: 56px;
			border: 1px solid var(--global-black-10);
			border-radius: 4px;
			font-size: 16px;
		}
	}

	&__trash-button {
		width: 40px !important;
		height: 40px !important;
		margin: 1px !important;
		border-radius: 4px !important;
		color: var(--link-color) !important;
		transition: color 0.2s ease-in-out;

		&:hover {
			color: var(--global-red) !important;
		}
	}

	&__operator-button {
		width: 40px !important;
		height: 40px !important;
		margin: 1px !important;
		border-radius: 4px !important;
		color: var(--link-color) !important;
		transition: color 0.2s ease-in-out;

		&:disabled {
			color: var(--global-grey) !important;
		}
	}
}
