.dark-bg {
  position: fixed;
  z-index: 1;
  top: 50%;
  left: 50%;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.2);
  transform: translate(-50%, -50%);
}

.centered {
  position: fixed;
  z-index: 10;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.modal {
  min-height: 200px;
  width: 250px;
  border-radius: 4px;
  background: var(--global-white);

  &__header {
    display: flex;
    align-items: center;
    height: 50px;
    padding: 15px;
    overflow: hidden;
  }

  &__content {
    padding: 15px;
    font-size: 14px;
    color: var(--global-black);
  }

  &__actions {
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 100%;
    padding: 15px;
  }

  &__title {
    margin: 0;
    color: var(--global-black);
    font-weight: 500;
    font-size: 16px;
  }

  &__close-button {
    cursor: pointer;
    align-self: flex-end;
    position: absolute;
    right: 0;
    top: 0;
    width: 50px;
    height: 50px;
    border: none;
    border-radius: 50%;
    background: transparent;
  
    svg {
      fill: var(--global-black);
    }

    &:hover {
      svg {
        fill: var(--global-red);
      }
    }
  }

  &__action-button {
    cursor: pointer;
    height: 40px;
    width: 100px;
    border: none;
    border-radius: 4px;
    background: var(--global-red);
    font-size: 14px;
    font-weight: 500;
    color: var(--global-white);
    transition: all 0.25s ease;
  
    &:hover {
      box-shadow: 0 10px 10px -10px rgba(255, 62, 78, 0.6);
    }
  }

  &__cancel-button {
    cursor: pointer;
    height: 40px;
    width: 100px;
    border: none;
    border-radius: 4px;
    background: var(--global-white);
    font-size: 14px;
    font-weight: 500;
    color: var(--global-black);
    transition: all 0.25s ease;
  
    &:hover {
      background: var(--global-black-05);
    }
  }
}
