.input-container {
	display: flex;
	flex-direction: column;
	align-items: flex-start;

	&__label {
		display: block;
		margin: 4px 0;
		color: var(--global-black-60);
		font-size: 12px;
	}

	&__required {
		color: var(--global-red);
		font-size: 12px;
	}

	&__input {
		height: 40px;
		width: 100%;
		padding: 1px 12px;
		border: 1px solid var(--global-grey-30);
		border-radius: 4px;
		font-size: 14px;

		&--error {
			border-color: var(--global-red);
		}
	}

	&__error {
		margin: 4px 0;
		color: var(--global-red);
		font-size: 12px;
	}
}
