.add-to-cart-button {
	cursor: pointer;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	width: 32px;
	height: 32px;
	margin: 0 0 0 auto;
	padding: 0;
	border-radius: 4px;
	border: none;
	background-color: var(--global-white);

	&:hover {
		background-color: var(--global-5);
	}

	svg {
		color: var(--global);
	}

	&.disabled {
		svg {
			color: var(--global-black-60);
		}
	}
}
