.shop-picker {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 12px 0;

  &__selector {
    width: 100% !important;
    height: 40px !important;
    text-align: start;
  }

  &__title {
    display: block;
    margin: 4px 0;
    color: var(--global-black-60);
    font-size: 12px;
  }
}
