.share-tg-button {
	cursor: pointer;
	border-radius: 4px;
	border: none;
	background-color: var(--global-white);
	transition: background-color 0.2s ease-in-out;

	svg {
		vertical-align: middle;
		fill: #229ed9;
		transition: all 0.2s ease;
		font-size: 26px;
	}

	&:hover {
		background-color: var(--global-5);
	}
}
