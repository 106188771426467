.price-filter {
  padding: 16px 16px 0 16px;

  &__form {
    padding: 8px 8px 0 8px;
  }

  &__inner {
    display: flex;
    flex-direction: row;
    align-items: center;
    border: none;
  }

  &__input {
    width: 32%;
    height: 32px;
    padding: 0 8px;
    border: 1px solid var(--global-grey-30);
    border-radius: 4px;
    background-color: var(--global-white);
    font-size: 16px;

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      appearance: none;
    }
  }

  &__dash {
    margin: 0 4px;
    color: var(--global-grey-50);
    font-size: 10px;
  }

  &__approve-button {
    cursor: pointer;
    height: 32px;
    margin-left: 16px;
    padding: 1px 16px;
    border: none;
    border-radius: 4px;
    box-shadow: inset 0 0 0 1px var(--button-shadow);
    background-color: var(--global-black-05);
    font-size: 14px;
    text-transform: uppercase;
    color: var(--link-color);
  }

  &__range {
    margin: 8px 16px 0 16px;
  }
}
