.warehouse-picker {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-top: 16px;

  &__title {
    font-size: 14px;
    padding-left: 12px;
    color: var(--global-black);
  }

  svg {
    width: 24px;
    height: 24px;
    fill: var(--global-black-60);
  }

  &__warehouse {
    cursor: pointer;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: 100%;

    p {
      margin: 0;
    }
  }

  &__info {
    cursor: pointer;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 0;
    border: none;
    background: none;
  }
}
