.tabs {
	margin-bottom: 32px;
	background-color: var(--global-black-05);

	&__list {
		display: flex;
		flex-direction: row;
		max-width: 1280px;
		overflow: auto;
	}

	&__item {
		margin-right: 16px;
		border-bottom: 2px solid var(--global-black-05);
		color: var(--link-color);

		&--active {
			border-bottom: 2px solid var(--global);
			color: var(--global);
		}

		button {
			background: none;
			border: none;
			color: inherit;
		}
	}

	&__link {
		cursor: pointer;
		display: inline-block;
		height: 40px;
		padding: 0 8px;
		line-height: 40px;
		font-size: 14px;
		white-space: nowrap;
	}
}
