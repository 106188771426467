.like-button {
	cursor: pointer;
	border-radius: 4px;
	border: none;
	background-color: var(--global-white);
	transition: background-color 0.2s ease-in-out;

	svg {
		vertical-align: middle;
		fill: var(--global-yellow);
		transition: all 0.2s ease;
	}

	&:hover {
		background-color: var(--global-5);
	}
}
