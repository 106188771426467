.side-bar {
  position: fixed;
  left: 0;
  z-index: 1000;
  width: 100%;
  height: 100%;
  transition: transform 0.5s cubic-bezier(0.25, 0.1, 0.25, 1);
  transform: translateX(-100%);

  &--open {
    display: flex;
    transform: translateX(0);
  }

  &__wrapper {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    width: 320px;
    height: 100%;
    overflow-y: auto;
    -ms-overflow-style: none;
    scrollbar-width: 0;
    background-color: var(--global-white);

    &::-webkit-scrollbar {
      display: none;
    }
  }
}
