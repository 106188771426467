.characteristics {
  margin-bottom: 12px;
  border-bottom: 1px solid var(--global-black-10);

  &__item {
    display: flex;
    flex-direction: row;
    margin-bottom: 24px;
  }

  &__label {
    align-self: flex-start;
    width: 30%;
    font-size: 14px;
    word-break: break-word;

    span {
      display: inline-block;
      padding-right: 16px;
      background-color: var(--global-white);
    }
  }

  &__value {
    width: 70%;
    padding-left: 16px;
    font-size: 14px;
    word-break: break-word;

    a {
      cursor: pointer;
      color: var(--link-color);

      &:hover {
        color: var(--link-hover-color);
      }
    }
  }
}
