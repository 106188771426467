.checkout-tile {
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 16px 0;
	border-bottom: 1px solid var(--global-black-10);

	figcaption {
		width: 70%;
		color: var(--link-color);
	}

	dd {
		margin: 0;
	}

	&:hover {
		figcaption {
			color: var(--link-hover-color);
		}
	}

	&__title {
		width: 100%;
		font-size: 12px;
		line-height: 17px;
	}

	&__figure {
		display: flex;
		flex-direction: row;
		justify-content: center;
		align-items: center;
		margin: 0;
	}

	&__picture {
		position: relative;
		justify-content: center;
		flex-shrink: 0;
		display: flex;
		flex-direction: row;
		align-items: center;
		width: 56px;
		height: 56px;
		margin-right: 16px;

		img {
			max-width: 100%;
			max-height: 100%;
			width: auto;
			height: auto;
			border-radius: 4px;
		}
	}

	&__skeleton {
		width: 100%;
		height: 100%;
		border-radius: 4px;
		background-color: var(--global-20);
	}

	&__options {
		display: flex;
		flex-direction: row;
		width: 100%;
		margin-bottom: 0px;
	}

	&__option {
		flex-grow: 1;
		text-align: center;

		&--quantity {
			flex-grow: 0;
			padding-left: 8px;
			padding-right: 8px;
		}
	}

	&__label {
		display: block;
		margin-bottom: 4px;
		color: var(--global-black-60);
	}

	&__digit {
		font-size: 14px;
		color: var(----global-black);
	}

	&__price {
		font-size: 14px;
		color: var(----global-black);

		&--old {
			margin-top: 2px;
			font-size: 10px;
			color: var(--global-black-60);
			text-decoration: line-through;
		}
	}

	&__discount {
		color: var(--global-red);
	}
}
