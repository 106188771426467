.phoneNumber {
  margin-bottom: 3px;
  border: none;
  background: none;
  color: var(--link-color);
  font-size: 14px;
  cursor: pointer;

  &:hover {
    color: var(--link-hover-color);
  }
}
