.selected-container {
	width: 100%;
	padding: 16px;
	border: 1px solid transparent;

	&__active {
		border-color: var(--global);
		border-radius: 4px;
	}

	&__title {
		margin: 0;
		font-size: 14px;
		color: var(--global-black);
	}

	&__radio {
		width: 24px;
		height: 24px;
		margin-right: 12px;
		margin-left: 0;
		border: 1px solid var(--global-grey-30);
		accent-color: var(--global);
	}

	&__header {
		display: flex;
		flex-direction: row;
		align-items: center;
	}
}
