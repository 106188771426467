.side-menu {
	&__header {
		display: flex;
		flex-direction: row;
		flex-shrink: 0;
		align-items: center;
		justify-content: space-between;
		padding: 8px 8px 8px 16px;
		background-color: var(--global-black);

		&-wrapper {
			display: flex;
			align-items: center;
		}

		&-title {
			max-width: 210px;
			overflow: hidden;
			white-space: nowrap;
			text-overflow: ellipsis;
			font-size: 16px;
			color: var(--global-white);
			font-family: 'Raleway', sans-serif;
			font-weight: 800;
			text-transform: uppercase;
		}
	}

	&__close {
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: center;
		width: 40px;
		height: 40px;
		padding: 0;
		border-radius: 4px;
		border: none;
		background: none;
		transition: all 0.2s ease-in-out;

		&:hover {
			background-color: #ffffff0d;
		}

		svg {
			width: 16px;
			height: 16px;
			fill: var(--global-white);
		}
	}

	&__body {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
	}

	&__list {
		width: 100%;
		padding-left: 0px;
		padding-top: 4px;
		border-bottom: 1px solid var(--global-black-10);
		list-style: none;
	}

	&__item {
		padding: 2px 8px;
	}

	&__counter {
		position: absolute;
		right: 0;
		display: flex;
		flex-direction: row;
		flex-shrink: 0;
		align-items: center;
		justify-content: center;
		width: 24px !important;
		height: 24px !important;
		margin-right: 16px;
		border-radius: 50%;
		background-color: var(--global) !important;
		font-size: 12px;
		color: var(--global-white);
	}

	&__bordered-section {
		padding: 5px 0;
		border-top: 1px solid var(--global-black-10);
	}

	&__button {
		position: relative;
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: flex-start;
		width: 100%;
		height: 48px;
		padding: 0px 8px;
		border-radius: 4px;
		border: none;
		background: none;
		font-size: 14px;
		color: var(--global-black);
		transition: background-color 0.2s ease-in-out;

		&:hover {
			background-color: var(--global-20);

			span {
				background-color: var(--global-20);
			}
		}

		span {
			display: flex;
			flex-direction: row;
			flex-shrink: 0;
			align-items: center;
			justify-content: center;
			width: 40px;
			height: 40px;
			margin-right: 16px;
			border-radius: 50%;
			background-color: var(--global-black-05);
			transition: background-color 0.2s ease-in-out;

			svg {
				width: 24px;
				height: 24px;
				fill: currentColor;
				transition: all 0.3s linear;
			}
		}
	}
}
