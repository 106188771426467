.contact-information {
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 100%;
	min-height: 48px;
	margin: 8px 0;
	padding: 0 8px;

	&__wrapper {
		display: flex;
		justify-content: space-between;
		flex-direction: row;
		width: 100%;
	}

	&__title {
		width: 75px;
		padding: 0 8px;
		font-size: 14px;
		color: var(--global-black-60);
	}

	&__phones {
		display: flex;
		flex-direction: column;
		align-items: flex-end;
		padding-right: 8px;
		font-size: 12px;

		a {
			min-width: max-content;
			margin: 4px 0px;

			&:hover {
				color: var(--link-hover-color);
			}
		}
	}

	&__button {
		position: relative;
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: flex-start;
		padding: 0;
		border: 1px solid var(--global-black-10);
		border-radius: 4px;
		background: none;
		font-size: 14px;
		color: var(--global-black);
		transition: background-color 0.2s ease-in-out;

		&:hover {
			border-color: var(--global-20);
			background-color: var(--global-20);

			span {
				background-color: var(--global-20);
			}
		}

		span {
			display: flex;
			flex-direction: row;
			align-items: center;
			justify-content: center;
			width: 40px;
			height: 40px;
			border-radius: 4px;
			transition: background-color 0.2s ease-in-out;

			svg {
				width: 24px;
				height: 24px;
				fill: currentColor;
				transition: all 0.3s linear;
			}
		}
	}

	&__links {
		width: 100%;
		display: flex;
		justify-content: flex-end;
		padding: 8px;

		:nth-child(1) {
			margin-left: 0px;
		}

		a {
			cursor: pointer;
			margin-left: 8px;
		}
	}
}
