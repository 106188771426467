.carousel-images {
  margin-bottom: 32px;

  &__wrapper {
    min-height: 25rem;
    width: 100%;
    height: 25rem;
  }

  &__image {
    width: 100%;
    height: 25rem;
    object-fit: scale-down;
  }
}
