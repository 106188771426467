.cart-page {
	overflow: hidden;

	&__header {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: center;
		width: 100%;

		h1 {
			font-size: 24px;
		}

		&-wrapper {
			display: flex;
			flex-direction: row;
			justify-content: space-between;
			align-items: center;
		}
	}

	&__remove-all-button {
		border: none;
		background: none;
		font-size: 16px;
		color: var(--link-color);
		transition: color 0.1s ease-in-out;

		&:hover {
			color: var(--global-red);
		}
	}

	&__products {
		min-height: 75vh;
		width: 100%;
		margin: 0 auto;
		margin-bottom: 6px;
		padding: 16px;
		padding-bottom: 150px;
		border-radius: 4px;
		border: 1px solid var(--global-black-10);

		:first-child {
			border: none;
		}

		&.center {
			display: flex;
			align-items: center;
			justify-content: center;
		}
	}

	&__total {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: center;
	}

	&__footer-container {
		position: fixed;
		left: 0;
		bottom: 0;
		display: flex;
		justify-content: center;
		width: 100%;
		padding: 16px;
	}

	&__empty {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		width: 100%;

		svg {
			width: 100px;
			height: 100px;
			margin-bottom: 24px;
			fill: var(--link-color);
		}

		h4 {
			margin: 0;
			font-size: 20px;
			font-weight: 400;
		}

		p {
			margin-top: 16px;
			font-size: 14px;
			color: var(--global-black-60);
			transition: background-color 0.2s ease-in-out;

			&:hover {
				color: var(--link-hover-color);
			}
		}
	}

	&__footer {
		width: 100%;
		max-width: 900px;
		display: flex;
		flex-direction: column;
		padding: 16px;
		border-radius: 4px;
		border: 1px solid var(--global);
		background-color: var(--global-black-05);

		p {
			margin: 0;
			font-size: 24px;
			color: var(--global-black);
		}
	}

	&__checkout-button {
		display: flex;
		justify-content: center;
		align-items: center;
		height: 50px;
		width: 100%;
		margin-top: 16px;
		border: none;
		border-radius: 4px;
		background: var(--global);
		font-size: 16px;
		color: var(--global-white);
		transition: background-color 0.2s ease-in-out;

		&:hover {
			background-color: var(--global-50);
		}
	}
}
