.auth-modal {
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: rgba(0, 0, 0, 0.5);

	.auth-modal__content {
		background-color: var(--global-white);
		border-radius: 12px;
		box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
		padding: 30px;
		text-align: center;
		max-width: 400px;
		width: 90%;
		transition: transform 0.3s ease-in-out;

		&:hover {
			transform: translateY(-5px);
		}

		h2 {
			font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
			font-weight: 500;
		}

		.auth-modal__button {
			border-radius: 8px;
			padding: 10px 20px;
			font-size: 14px;
			font-weight: 400;
			margin-top: 15px;
			width: 100%;
			color: var(--global-white);

			&:hover {
				opacity: 0.9;
			}

			& + .auth-modal__button {
				margin-top: 10px;
			}
		}
	}
}
