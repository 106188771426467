.search-form {
  display: flex;
  flex-direction: row;
  transition: all 0.25s ease-in-out;

  &__inner {
    position: relative;
    display: flex;
    flex-direction: row;
    flex: 1;
  }

  &__state--unfolded {
    position: absolute;
    left: 0;
    top: 0;
    z-index: 10;
    width: 100%;
    padding: 8px 16px 0px 16px;
  }

  &__back {
    cursor: pointer;
    display: none;
    flex-direction: row;
    flex-shrink: 0;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    padding: 0;
    border: none;
    background: var(--global-white);
    font-size: 15px;

    &:hover {
      svg {
        fill: var(--link-hover-color);
      }
    }

    &--visible {
      display: flex;
    }

    svg {
      font-size: 15px;
      fill: var(--link-color);
    }
  }

  &__input {
    width: 100%;
    height: 40px;
    outline: none;
    border: none;
    background-color: var(--global-white);
    font-size: 15px;

    &-wrapper {
      display: flex;
      flex-direction: row;
      align-items: center;
      overflow: hidden;
      width: 100%;
      padding-left: 12px;
      border-radius: 3px;

      &--active {
        padding-left: 0px;
        padding-right: 0px;
        border-radius: 3px 3px 0 0;
        background-color: var(--global-white);
      }
    }
  }

  &__clear {
    cursor: pointer;
    display: none;
    flex-direction: row;
    flex-shrink: 0;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    padding: 0px;
    border: none;
    background: var(--global-white);

    &:hover {
      svg {
        fill: var(--link-hover-color);
      }
    }

    &--visible {
      display: flex;
    }

    svg {
      font-size: 15px;
      fill: var(--link-color);
    }
  }
}
