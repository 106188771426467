.image-loader {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  // border: 1px solid var(--global-black-10);

  &__wrapper {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    position: relative;
  }

  &__icon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1;
  }
}

.image {
  &-hidden {
    position: absolute;
    z-index: 1;
    top: 0;
    visibility: hidden;
    opacity: 0;
  }

  &-visible {
    z-index: 1;
    visibility: visible;
    opacity: 1;
    transition: opacity 0.5s linear;
  }
}
