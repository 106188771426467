.wrapper {
	display: flex;
	flex-direction: column;
	min-width: 380px;
	align-items: center;
	width: 100%;
}

.page-overlay {
	position: fixed;
	z-index: 5;
	right: 0;
	left: 0;
	top: 0;
	bottom: 0;
	opacity: 0;
	background-color: rgba(0, 0, 0, 0.5);
	transform: translateY(-100%);
	transition: transform 0.1ms ease-in-out 0.2s, opacity 0.2s ease-in-out;

	&_state_visible {
		display: block;
		opacity: 1;
		transform: translateY(0);
		transition: opacity 0.2s ease-in-out;
	}
}

.main-side {
	display: flex;
	justify-content: center;
	width: 100%;
	padding-top: 56px;

	&__wrapper {
		display: flex;
		flex-direction: column;
		width: 100%;
		max-width: 1000px;
		margin: 0 auto;
		padding: 0 8px;
	}
}

.maintenance {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-between;
	padding: 36;

	&__logo {
		margin-top: 30px;
		margin-bottom: 70px;
	}

	img {
		margin: 30px;
	}

	h3 {
		text-align: center;
	}
}

@media (max-width: 600px) {
	.main-side__wrapper {
		padding: 0 16px;
	}
}

@media (min-width: 601px) {
	.main-side__wrapper {
		padding: 0 32px;
		border-left: 1px solid var(--global-black-10);
	}
}
