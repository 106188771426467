.input-container {
	display: flex;
	flex-direction: column;

	&__label {
		display: block;
		margin: 4px 0;
		color: var(--global-black-60);
		font-size: 12px;
	}

	&__required {
		color: var(--global-red);
		font-size: 12px;
	}

	&__input {
		height: 40px !important;
		width: 100% !important;
		border: 1px solid var(--global-grey-30) !important;
		border-radius: 4px !important;
		font-size: 16px !important;

		&--error {
			border-color: var(--global-red) !important;
		}
	}

	&__error {
		margin: 4px 0;
		color: var(--global-red);
		font-size: 12px;
	}
}
