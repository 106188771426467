.scroll-top-page-button {
	cursor: pointer;
	opacity: 0;
	position: fixed;
	right: 10px;
	bottom: 80px;
	z-index: -1;
	display: inline-flex;
	justify-content: center;
	align-items: center;
	width: 45px;
	height: 45px;
	outline: none;
	overflow: hidden;
	border: none;
	border-radius: 50%;
	background-color: var(--global-white);
	box-shadow: 0 0 2px 0 rgba(51, 51, 51, 0.05),
		0 1px 4px 0 rgba(51, 51, 51, 0.15);
	transition: opacity 0.3s, color 0.1s, z-index 0.3s;

	&.active {
		opacity: 0.85;
		z-index: 4;

		&:hover {
			opacity: 1;
		}
	}

	svg {
		fill: var(--global);
		height: 36px;
		width: 36px;
	}
}
