.variant-options {
	&__wrapper {
		display: flex;
		align-items: center;
	}

	&__label {
		margin-bottom: 8px;
		font-size: 14px;

		span {
			color: var(--global-black-60);
		}
	}

	&__list {
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		overflow: hidden;
	}

	&__item {
		display: flex;
		justify-content: center;
		align-items: center;
	}

	&__disabled {
		opacity: 0.6;
	}

	&__preview {
		margin-right: 4px;
		padding: 4px;
		border-radius: 4px;
		border: 1px solid transparent;
		transition: all 0.2s ease-in-out;
		background-color: transparent;

		&--active {
			padding: 4px;
			border-color: var(--global);
			background-color: var(--global-05);
			color: var(--global-black);
		}

		&.variant-options__strikethrough {
			text-decoration: line-through;
		}

		span {
			cursor: pointer;
			display: block;
			line-height: 24px;
			min-width: 24px;
			height: 24px;
			border-radius: 4px;
			background-color: var(--global-white);
			box-shadow: inset 0 0 0 2px var(--box-shadow);
			padding: 0 4px;
		}
	}
}
