.powered-by-container {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	margin: 16px 0;
	text-align: center;
}

.powered-by-link {
	display: flex;
	align-items: center;
	justify-content: center;
	text-decoration: none;
}

.powered-by-logo {
	width: 150px;
	margin-bottom: 8px;
}
