.filters-menu {
  &__item {
    border-bottom: 1px solid var(--border-grey-color);
  }

  &__link {
    cursor: pointer;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 48px;
    padding: 0 8px 0 16px;
    background: none;
    border: none;
    text-align: left;

    &:hover {
      svg {
        fill: var(--link-hover-color);
      }
    }

    svg {
      width: 24px;
      height: 24px;
      fill: var(--link-color);
    }
  }

  &__title {
    font-size: 16px;
    color: var(--global-black);
  }

  &__values {
    display: inline-block;
    flex-shrink: 0;
    max-width: 30%;
    margin-left: auto;
    margin-right: 8px;
    overflow: hidden;
    white-space: nowrap;
    font-size: 14px;
    color: var(--global-black);
    text-overflow: ellipsis;
  }
}
