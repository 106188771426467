.checkout-page {
  &__header {
    &-wrapper {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
    }
  }

  &__minimum {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 1rem;
    padding: 0.5rem;
    background-color: var(--global-yellow-5);
    border: 1px solid var(--global-yellow);
    border-radius: 10px;

    p {
      padding-left: 0.5rem;
      font-size: 12px !important;
    }
  }
}
