.product-statuses {
	display: flex;
	margin-bottom: 16px;

	&__label {
		display: inline-flex;
		flex-direction: row;
		align-items: center;
		height: 32px;
		padding: 0 16px;
		border-radius: 4px;
		font-size: 14px;

		svg {
			width: 16px;
			height: 16px;
			margin-right: 12px;
		}
	}
}

.status-label {
	&--green {
		background-color: var(--global-5);
		color: var(--global);
	}

	&--gray {
		background-color: var(--global-black-05);
		color: var(--global-black-60);
	}

	&--yellow {
		background-color: var(--global-yellow-05);
		color: var(--global-yellow);
	}
}

.no-label {
	margin: 0;

	p {
		margin: 0;
		padding: 0;
		background: none;
		font-size: 12px;

		svg {
			display: none;
		}
	}
}
