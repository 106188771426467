.other-filters {
  margin: 16px 16px 16px 0;

  &__attributes {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    overflow-y: auto;
    -ms-overflow-style: none;
    scrollbar-width: 0;
  
    &::-webkit-scrollbar {
      display: none;
    }
  }
}
