.back-button {
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 56px;
  height: 56px;
  border: none;
  background-color: var(--global-white);
  transition: all 0.2s ease;

  &:hover {
    svg {
      fill: var(--link-hover-color);
    }
  }

  svg {
    width: 24px;
    height: 24px;
    fill: var(--global-black-60);
    transition: all 0.2s ease;
  }
}
