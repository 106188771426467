.wishlist-tile {
	display: flex;
	flex-direction: row;
	width: 100%;
	padding: 16px;
	border-bottom: 1px solid var(--global-black-10);
	background-color: var(--global-white);

	&__details {
		width: 50%;
		padding: 0 0 0 10px;
	}

	&__picture {
		max-height: 128px;
		width: 50%;

		img {
			width: 100%;
			height: 100%;
			object-fit: contain;
			border-radius: 4px;
		}
	}

	&__skeleton {
		width: 100%;
		height: 100%;
		border-radius: 4px;
		background-color: var(--global-20);
	}

	&__title {
		margin: 0;
		overflow: hidden;
		font-size: 12px;
		line-height: 16px;
		color: var(--global-black);
	}

	&__price-value {
		display: flex;
		flex-direction: column;
		justify-content: flex-end;
		height: 50px;
		padding-bottom: 4px;
	}

	&__price-current {
		padding-top: 0;
		font-size: 20px;
	}

	&__price-old {
		height: 18px;
		font-size: 14px;
	}

	&__quantity {
		margin-top: 10px;
		font-size: 12px;
		color: var(--global);

		p {
			margin: 0;
		}
	}

	&__price {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: flex-end;
		margin-top: 10px;
	}

	&__buy-button {
		cursor: pointer;
		display: flex;
		flex-direction: row;
		justify-content: center;
		align-items: center;
		width: 32px;
		height: 32px;
		margin: 0 0 0 auto;
		padding: 0;
		border-radius: 4px;
		border: none;
		background-color: var(--global-white);

		&:hover {
			background-color: var(--global-5);
		}

		svg {
			color: var(--global);
		}
	}

	.price-grey {
		color: var(--global-black-60);
		text-decoration: line-through;
	}

	.price-red {
		color: var(--global-red);
	}
}
