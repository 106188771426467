.breadcrumbs {
  display: flex;
  justify-content: space-between;

  &__list {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    margin-top: 16px;
    margin-bottom: 8px;
  }

  &__item {
    margin-top: 8px;
    margin-bottom: 8px;
  }

  &__link {
    cursor: pointer;
    display: flex;
    align-items: center;
    line-height: 16px;
    font-size: 14px;
    color: var(--link-color);

    &:hover {
      color: var(--link-hover-color);
    }

    &--inactive {
      cursor: default;
      color: var(--global-black);

      &:hover {
        color: var(--global-black);
      }
    }
  }

  &__icon {
    width: 18px;
    height: 18px;
    color: var(--global-grey-30);

    &--left {
      margin-right: 8px;
    }
  }
}
