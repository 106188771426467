.header {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	z-index: 6;
	width: 100%;
	background-color: var(--global-black);

	&__wrapper {
		display: flex;
		flex-direction: row;
		align-items: center;
		width: 100%;
		height: 56px;
		padding: 0 8px;
	}

	&__menu {
		height: 40px;
		margin-right: 8px;
	}

	&__search {
		flex-grow: 1;
		margin-left: 8px;
		border-radius: 3px;
		background-color: var(--global-white);
	}

	&__cart {
		height: 40px;
		margin-left: 8px;
	}

	&__logout {
		margin-left: 8px;
		margin-right: 8px;
	}

	&__open-browser {
		display: flex;
		align-items: center;
	}
}

.menu-button {
	cursor: pointer;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	width: 40px;
	height: 40px;
	padding: 0;
	border: none;
	border-radius: 4px;
	background: none;
	transition: background-color 0.2s ease-in-out;

	&:hover {
		background-color: var(--header-hover-color);
	}

	svg {
		width: 24px;
		height: 24px;
		fill: var(--global-white);
	}
}

.cart-button {
	cursor: pointer;
	position: relative;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	width: 40px;
	height: 40px;
	padding: 0;
	border-radius: 4px;
	border: none;
	background: none;
	transition: background-color 0.2s ease-in-out;

	&__counter {
		position: absolute;
		right: 0;
		top: 0;
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: center;
		min-width: 16px;
		height: 16px;
		padding-left: 4px;
		padding-right: 4px;
		border-radius: 50px;
		background-color: var(--global);
		font-size: 10px;
		color: var(--global-white);
	}

	&:hover {
		background-color: var(--header-hover-color);
	}

	svg {
		width: 24px;
		height: 24px;
		fill: var(--global-white);
	}
}

.logout-button {
	cursor: pointer;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	height: 40px;
	padding: 0 12px;
	border-radius: 4px;
	border: none;
	background-color: var(--global);
	color: var(--global-white);
	font-size: 14px;
	transition: background-color 0.2s ease-in-out;

	&:hover {
		background-color: var(--header-hover-color);
	}
}

.open-in-browser-button {
	cursor: pointer;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	width: 40px;
	height: 40px;
	padding: 0;
	border: none;
	border-radius: 4px;
	background: none;
	transition: background-color 0.2s ease-in-out;

	&:hover {
		background-color: var(--header-hover-color);
	}

	svg {
		width: 24px;
		height: 24px;
		fill: var(--global-white);
	}
}
