.in-total {
	display: flex;
	flex-direction: column;
	margin-bottom: 16px;
	padding: 16px;
	border: 1px solid var(--global-black-10);
	background-color: var(--global-black-05);

	&__title {
		margin: 0;
		margin-bottom: 16px;
		font-size: 28px;
		font-weight: 400;
	}

	&__info {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: center;
		margin-bottom: 12px;
		padding-bottom: 12px;
		border-bottom: 1px solid var(--global-black-10);

		&-label {
			margin: 0;
			margin-right: 15px;
			font-size: 14px;
			color: var(--global-black-60);
		}

		&-value {
			margin: 0;
			font-size: 14px;
			color: var(--global-black);

			&.total {
				font-size: 24px;
			}
		}
	}

	&__action-button {
		cursor: pointer;
		height: 48px;
		padding: 0 12px;
		font-size: 18px;
		border: none;
		border-radius: 4px;
		background: var(--global);
		color: var(--global-white);
		transition: background-color 0.2s ease-in-out;
	}

	@media (hover: none) {
		&__action-button:hover {
			background-color: var(--global);
		}
	}

	&__action-button:disabled {
		background-color: var(--global-black-60);
	}

	&__action-button:disabled:hover {
		background-color: var(--global-black-60);
	}
}
