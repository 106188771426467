.shop-adresses-page {
  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid var(--global-black-10);
  }

  &__back-button {
    cursor: pointer;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 56px;
    height: 56px;
    border: none;
    background-color: var(--global-white);
    transition: all 0.2s ease;

    &:hover {
      svg {
        fill: var(--link-hover-color);
      }
    }

    svg {
      width: 24px;
      height: 24px;
      fill: var(--global-black-60);
      transition: all 0.2s ease;
    }
  }

  &__item {
    padding: 12px 0px;
    border-bottom: 1px solid var(--global-black-10);
  }

  &__info {
    &-item {
      display: flex;
      flex-direction: row;
      margin-bottom: 24px;
    }
  }

  &__label {
    align-self: flex-start;
    width: 40%;
    font-size: 14px;
    word-break: break-word;

    span {
      display: inline-block;
      padding-right: 16px;
      background-color: var(--global-white);
    }
  }

  &__value {
    width: 60%;
    padding-left: 16px;
    font-size: 14px;
    word-break: break-word;
  }

  .characteristics__value {
    img {
      max-width: 100%;
      height: auto;
    }
  }
}
