.categories-page {
  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__back-button {
    cursor: pointer;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 56px;
    height: 56px;
    border: none;
    background-color: var(--global-white);
    transition: all 0.2s ease;

    &:hover {
      svg {
        fill: var(--link-hover-color);
      }
    }

    svg {
      width: 24px;
      height: 24px;
      fill: var(--global-black-60);
      transition: all 0.2s ease;
    }
  }
}

.active-category {
  margin-top: 24px;
  margin-bottom: 24px;
  font-size: 16px;
  font-weight: 400;
  color: var(--global-black);

  &__link {
    padding-left: 5px;
    color: var(--link-color);

    &:hover {
      color: var(--link-hover-color);
    }
  }
}

.menu {
  padding: 0 16px;
  border-top: 1px solid var(--global-black-10);
  border-bottom: 1px solid var(--global-black-10);

  &__button {
    margin: 0;
    padding: 0;
    outline: none;
    border: none;
    background: none;
  }

  &__link {
    cursor: pointer;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 40px;
    font-size: 16px;
    color: var(--link-color);

    &:hover {
      span {
        color: var(--link-hover-color);
      }
    }

    svg {
      width: 24px;
      height: 24px;
      fill: var(--global-black-60);
    }
  }
}
