.products-images {
  &__list {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  &__item {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-bottom: 48px;

    img {
      max-width: 100vw;
    }
  }
}
