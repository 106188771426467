.header-filter {
  display: flex;
  flex-direction: row;
  flex-shrink: 1;
  align-items: center;
  justify-content: space-between;
  padding: 0 16px 0 8px;
  border-bottom: 1px solid var(--global-black-10);
  background-color: var(--global-white);

  &__back-button {
    cursor: pointer;
    flex: 1;
    width: 100%;
    height: 56px;
    padding: 0;
    border: none;
    background: none;
    text-align: left;
    font-size: 20px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    color: var(--global-black);

    &:hover {
      svg {
        fill: var(--link-hover-color);
      }
    }

    svg {
      width: 24px;
      height: 24px;
      vertical-align: sub;
      fill: var(--link-color);
    }
  }

  &__reset-button {
    cursor: pointer;
    height: 26px;
    padding: 0 12px;
    border: none;
    border-radius: 50px;
    background: var(--global-red);
    font-size: 14px;
    color: var(--global-white);
    white-space: nowrap;
  }
}
