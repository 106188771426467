.shop-logo {
	cursor: pointer;
	margin-right: 8px;
	width: 40px;
	height: 40px;
	border-radius: 100%;
	background-color: var(--global-white);

	&__picture {
		width: 40px;
		height: 40px;
		border-radius: 100%;
		overflow: hidden;

		img {
			width: 100%;
			height: 100%;
			object-fit: cover;
			border-radius: 100%;
		}
	}

	&__skeleton {
		width: 40px;
		height: 40px;
		display: flex;
		justify-content: center;
		align-items: center;
		border-radius: 100%;
		background-color: var(--global-20);
		color: var(--global-black);
	}
}
