.loader {
  &__overlay {
    position: fixed;
    left: 0;
    top: 60px;
    width: 100%;
    height: 100%;
    z-index: 9999;
    background-color: rgba(0, 0, 0, 0.5);
  }
}

.loader-full {
  &__overlay {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 9999;
    background-color: rgba(0, 0, 0, 0.5);
  }
}

.loader__product--full {
  height: 320px;
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 16px;
  border-bottom: 1px solid var(--global-black-10);
  border-right: 1px solid var(--global-black-10);
}

.loader__product--short {
  height: 161px;
  width: 100%;
  display: flex;
  padding: 16px;
  border-bottom: 1px solid var(--global-black-10);
  border-right: 1px solid var(--global-black-10);
}
