.block-title {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 16px;

  &__info {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  &__number {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 24px;
    height: 24px;
    margin-right: 16px;
    border-radius: 50%;
    background-color: var(--global-black-10);
    font-size: 14px;
  }

  &__title {
    margin: 0;
    font-weight: 400;
    font-size: 16px;
  }

  &__action-button {
    width: 32px !important;
    height: 32px !important;
    border-radius: 4px !important;
    color: var(--link-color) !important;

    svg {
      font-size: 16px;
    }
  }
}
