.error-page {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 100vh;
	background-color: var(--global-black-10);
	text-align: center;

	&__content {
		max-width: 600px;
		padding: 20px;
		background-color: var(--global-white);
		box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
		border-radius: 8px;
	}

	&__message {
		font-size: 1.2em;
		margin-bottom: 24px;
		color: var(--global-grey);
	}

	&__link {
		font-size: 1em;
		color: var(--global);
		text-decoration: none;
		border: 1px solid var(--global);
		padding: 10px 20px;
		border-radius: 5px;
		transition: background-color 0.3s, color 0.3s;

		&:hover {
			background-color: var(--global);
			color: var(--global-white);
		}
	}
}
