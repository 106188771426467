.order-tile {
	margin-bottom: 30px;
	padding: 16px;
	border: 1px solid var(--global-black-10);
	border-radius: 4px;

	&__collapse-button {
		position: absolute;
		right: 0;
		top: 8px;
		width: 40px;
		height: 40px;
		border: none;
		background: none;

		svg {
			width: 24px;
			height: 24px;
			fill: var(--link-color);
		}
	}
}

.heading {
	position: relative;

	&__flex {
		display: flex;
		flex-direction: column;
		align-items: center;
		min-height: 40px;
		padding-right: 24px;
	}

	&__status {
		width: 100%;
		margin-bottom: 8px;
	}

	&__title {
		display: flex;
		flex-direction: row;
		align-items: center;
		margin-bottom: 16px;
		font-size: 14px;
		color: var(--global-black-60);
	}

	&__price {
		width: 100%;
		margin-bottom: 8px;
	}

	&__label {
		font-size: 12px;
		color: var(--global-black-60);
	}

	&__value {
		font-size: 14px;
	}

	&__goods {
		display: flex;
		flex-direction: row;
		width: 100%;

		&-item {
			display: flex;
			flex-direction: row;
			align-items: center;
			justify-content: center;
			width: 40px;
			height: 40px;
			margin-right: 10px;
			cursor: pointer;

			img {
				max-width: 100%;
				max-height: 100%;
				width: auto;
				height: auto;
				border-radius: 4px;
			}

			div {
				width: 100%;
				height: 100%;
				border-radius: 4px;
				background-color: var(--global-20);
			}
		}
	}
}

.order-details {
	display: flex;
	flex-direction: column;
	padding-top: 16px;

	&__goods {
		width: 100%;
	}

	&__payment {
		margin-bottom: 24px;
	}
}

.goods-list {
	&__header {
		display: flex;
		align-items: center;
		margin-bottom: 16px;
		font-size: 14px;
		color: var(--global-black-60);
	}

	&__items {
		padding: 16px;
		border: 1px solid var(--global-black-10);
		border-radius: 4px;
	}
}

.payment-info {
	&__item {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: center;
		font-size: 14px;
	}

	&__item {
		display: flex;
		flex-direction: row;
		align-items: center;
		margin-bottom: 16px;
	}

	&__label {
		font-size: 12px;
		color: var(--global-black-60);
	}

	&__value {
		width: 50%;
		font-size: 12px;
		text-align: right;
		overflow-wrap: break-word;

		img {
			max-width: 100%;
			height: auto;
		}
	}
}

.repeate-button {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 50px;
	width: 100%;
	margin-top: 16px;
	border: none;
	border-radius: 4px;
	background: var(--global);
	font-size: 16px;
	color: var(--global-white);
	transition: background-color 0.4s ease-in-out;

	&:hover {
		background-color: var(--global-50);
	}
}
