.orders-page {
  overflow: hidden;

  &__header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;

    h1 {
      font-size: 24px;
    }
  }

  &__wrapper {
    min-height: 75vh;
    width: 100%;
    margin: 0 auto;
    margin-bottom: 6px;
    padding: 16px;
    padding-bottom: 150px;
    border-radius: 4px;
    border: 1px solid var(--global-black-10);
  }

  &__empty {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;

    h4 {
      margin: 0;
      font-size: 20px;
      font-weight: 400;
    }

    p {
      margin-top: 16px;
      font-size: 14px;
      color: var(--global-black-60);
      transition: background-color 0.2s ease-in-out;

      &:hover {
        color: var(--link-hover-color);
      }
    }
  }
}
