html,
body {
  padding: 0;
  margin: 0;
  font-family: 'Inter', sans-serif;
  background-color: var(--global-white);
  -ms-overflow-style: none;
  scrollbar-width: 0;

  &::-webkit-scrollbar {
    display: none;
  }
}

* {
  box-sizing: border-box;
  font-family: 'Inter', sans-serif;
  outline: none;
}

button {
  margin: 0;
  padding: 0;
  border: none;
  background: none;
}

a {
  color: inherit;
  text-decoration: none;
}

ul {
  margin: 0px;
  padding: 0px;
  list-style: none;
}

p {
  margin: 0px;
  padding: 0px;
}
