.footer-filter {
  margin-top: auto;
  padding: 16px;
  border-top: 1px solid var(--global-black-10);

  &__caption {
    margin-bottom: 16px;
    line-height: 1;
    text-align: center;
    font-size: 14px;
    color: var(--global-black-60);
  }

  &__buttons-wrapper {
    display: flex;
    flex-direction: row;
  }

  &__show-button {
    cursor: pointer;
    width: 100%;
    height: 40px;
    padding: 1px 16px;
    background-color: var(--link-color);
    border: none;
    border-radius: 4px;
    font-size: 16px;
    color: var(--global-white);

    &--margin {
      margin-left: 16px;
    }
  }

  &__back-button {
    cursor: pointer;
    width: 100%;
    height: 40px;
    padding: 1px 16px;
    background-color: var(--global-black-05);
    box-shadow: inset 0 0 0 1px var(--button-shadow);
    border: none;
    border-radius: 4px;
    font-size: 16px;
    color: var(--link-color);
  }
}
