.settlements {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin: 16px 0;
  padding: 12px;
  border: 1px solid var(--global-grey-30);
  border-radius: 4px;
  font-size: 14px;

  svg {
    width: 24px;
    height: 24px;
    fill: var(--global-black-60);
  }

  &__autocomplete {
    width: 100% !important;
  }

  &__settlement {
    cursor: pointer;
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    p {
      margin: 0;
    }
  }

  &__title {
    margin-bottom: 4px;
    font-size: 12px;
    color: var(--global-black-60);
  }

  &__city {
    font-size: 14px;
    color: var(--global-black);
  }

  &__region {
    margin-bottom: 4px;
    font-size: 12px;
    color: var(--global-black-60);
  }

  &__info {
    cursor: pointer;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 0;
    border: none;
    background: none;
  }

  &__location {
    display: flex;
    flex-direction: row;
    align-items: center;

    svg {
      margin-right: 16px;
    }
  }
}
