.about-us-page {
  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__images {
    margin-top: 32px;
  }

  &__back-button {
    cursor: pointer;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 56px;
    height: 56px;
    border: none;
    background-color: var(--global-white);
    transition: all 0.2s ease;

    &:hover {
      svg {
        fill: var(--link-hover-color);
      }
    }

    svg {
      width: 24px;
      height: 24px;
      fill: var(--global-black-60);
      transition: all 0.2s ease;
    }
  }

  &__content {
    margin-bottom: 32px;
    border-top: 1px solid var(--global-black-10);
    border-bottom: 1px solid var(--global-black-10);
  }

  &__description {
    margin: 32px 0;
    padding: 0 8px;
    word-break: break-word;
    font-size: 14px;

    img {
      max-width: 100%;
      height: auto;
    }
  }
}

.menu {
  padding: 0 16px;
  border-top: 1px solid var(--global-black-10);
  border-bottom: 1px solid var(--global-black-10);

  &__link {
    cursor: pointer;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 40px;
    font-size: 16px;
    color: var(--link-color);

    &:hover {
      span {
        color: var(--link-hover-color);
      }
    }

    svg {
      width: 24px;
      height: 24px;
      fill: var(--global-black-60);
    }
  }
}
